<!-- Sidebar Header starts -->
<div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'">
  <div class="logo clearfix">
    <a (click)="toggleSidebar()" class="logo-text float-left menu-icon">
      <img [src]="menuIconUrl" alt="" />
    </a>
    <!-- <a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;"
      (click)="toggleSidebar()">
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a> -->
    <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content main-menu-content" [perfectScrollbar] [disabled]="!perfectScrollbarEnable">
  <div class="nav-container">
    <ul class="navigation">
      <!-- First level menu -->
      <li appSidebarlink *ngFor="let menuItem of menuItems" [parent]="menuItem.title" [path]="menuItem.path"
        level="{{level + 1}}" [hasSub]="menuItem.class.includes('has-sub') ? true: false"
        [ngClass]="{'has-sub': menuItem.class.includes('has-sub') ? true: false}"
        [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'"
        [routerLinkActiveOptions]="{exact: false}">
        <a [ngClass]="{'active': checkIsSubItemsActive(menuItem)}"
          *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink" (click)="openSubMenu(menuItem)">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="getchildrenCount(menuItem.submenu) > 0 && !menuItem?.open"
            class="badge badge-menu-children badge-white">{{getchildrenCount(menuItem.submenu)}}</span>
        </a>
        <a routerLink="{{menuItem.path}}" *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
         
        </a>
        <a [href]="[menuItem.path]" target="_blank" *ngIf="menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
         
        </a>

      </li>
    </ul>
    <div class="sidebar-logo">
      <img class="sidebar-logo-img" alt="Keye logo" src="assets/img/logo.svg">
    </div>
  </div>
</div>
<div>
  <!-- Second level menu -->
  <div class="sub-menu-pnl" [ngClass]="{'open':showSubMenu}">
    <ul class="menu-content" *ngIf="subMenuItemObj.items.length > 0 ">
      <ng-container *ngFor="let menuSubItem of subMenuItemObj.items">
        <li>
          <a [routerLinkActive]="'active'" routerLink="{{menuSubItem.path}}">
            <span>{{menuSubItem.title | translate }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
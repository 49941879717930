import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from './config.service';
import { BehaviorSubject } from "rxjs";
import { LocalStorageService } from './storage.service';
import { SESSION_FILTER_KEY } from '../constants/app.constants';
import { GetDetectionResponseItems } from '@api-client/index';
import { DataStatus } from '@api-client/enum/data.enum';
import { environment } from '@environments/environment';

export enum DETECTION_TYPES {
  pothole = "pothole",
  crack= "crack",
  line = "lines",
}


@Injectable({
  providedIn: "root"
})
export class FilterService implements OnDestroy {

  private _defaultDateFrom = environment.options.defaultFilter.startDate;
  private _defaultDateTo = environment.options.defaultFilter.endDate;

  public rangeMinW: number = 0.0;
  public rangeMaxW: number = 300;
  public rangeCMin: number = 0.0;
  public rangeCMax: number = 1.0;

  public storedData: GetDetectionResponseItems[] = [];
  public itemsCount: number;
  public templateFilter: ITemplateFilter = this.setFilterValue();
  templateFilterSubject = new BehaviorSubject<ITemplateFilter>(this.templateFilter);
  templateFilter$ = this.templateFilterSubject.asObservable();

  constructor(private localStorageService: LocalStorageService, private config: ConfigService) {

  }
  public getAbsentValues = (arr1: GetDetectionResponseItems[], arr2: GetDetectionResponseItems[]) => {
    let res = [];
    res = arr1.filter(el => {
      return !arr2.find(obj => {
        return el.track_id === obj.track_id;
      });
    });
    return res;
  };
  public _applyFilter(_POI_Layer: GetDetectionResponseItems[], noCount?): GetDetectionResponseItems[] {

    //this.applytemplateFilterigChange();
    return _POI_Layer;


    const vMin = this.templateFilter.range[0];
    const vMax = this.templateFilter.range[1];

    const vCMin = this.templateFilter.confidence[0];
    const vCMax = this.templateFilter.confidence[1];

    // const dFrom = this.templateFilter.dateFrom? new Date(this.templateFilter.dateFrom.year,this.templateFilter.dateFrom.month,this.templateFilter.dateFrom.day, 0, 1, 1) : undefined;
    // const dTo = this.templateFilter.dateTo? new Date(this.templateFilter.dateTo.year,this.templateFilter.dateTo.month,this.templateFilter.dateTo.day, 23, 59, 59) : undefined;

    const result = _POI_Layer.filter(c =>
      this.templateFilter.types.indexOf(c.category) > -1 &&
      //timestamp
      // (dFrom == undefined || (dFrom != undefined && new Date(c.timestamp)>= dFrom)) &&
      // (dTo == undefined || (dTo != undefined && new Date(c.timestamp)<= dTo)) &&

      //range
      (vMin == undefined || (vMin != undefined && c.width >= vMin)) &&
      (vMax == undefined || (vMax != undefined && c.width <= vMax) || (vMax != undefined && vMax >= this.rangeMaxW)) &&

      //confidence
      (vCMin == undefined || (vCMin != undefined && c.score >= vCMin)) &&
      (vCMax == undefined || (vCMax != undefined && c.score <= vCMax))

      //status
      // && ((this.templateFilter.status.indexOf(0) > -1 && !c.status) || (this.templateFilter.status.indexOf(c.status)>-1))

    )
    // if(!noCount)
    // this.itemsCount = result.length;
    return result.sort(
      (objA, objB) => new Date(objA.timestamp).getTime() - new Date(objB.timestamp).getTime(),
    );
  }
  ngOnDestroy(): void {
    // this.localStorageService.saveData(SESSION_FILTER_KEY, this.templateFilter);
  }
  setFilterValue(): ITemplateFilter {
    const _session = this.localStorageService.getData(SESSION_FILTER_KEY);

    if (_session) return this.templateFilter = _session;
    else {

      const today = new Date();
      const priorDateTimeStamp = new Date().setDate(today.getDate() - 30);
      const priorDate = new Date(priorDateTimeStamp);

      this._defaultDateTo = { year: today.getFullYear(), month: (today.getMonth() + 1), day: today.getDate() }
      this._defaultDateFrom = { year: priorDate.getFullYear(), month: (priorDate.getMonth() + 1), day: (priorDate.getDate()) }

      return this.templateFilter = {
        types: [DETECTION_TYPES.pothole],
        dateFrom: this._defaultDateFrom,
        dateTo: this._defaultDateTo,
        range: [0, 3000],
        confidence: [0, 1],
        status: this._getSatusArray(),
        filter: {
          hidden: true
        }
      };
    }
  }
  switchStatus(e, type) {
    const index = this.templateFilter.status.indexOf(type);
    if (index > - 1 && !e.target.checked)
      this.templateFilter.status.splice(index, 1);
    else {
      if (index == -1) this.templateFilter.status.push(type);
    }

    // this.applytemplateFilterigChange();
  }
  switchType(e, type) {
    const index = this.templateFilter.types.indexOf(type);
    if (index > - 1 && !e.target.checked)
      this.templateFilter.types.splice(index, 1);
    else {
      if (index == -1) this.templateFilter.types.push(type);
    }

    //this.applytemplateFilterigChange();
  }
  resetType(t: FilterType) {

    switch (t) {
      case FilterType.type:
        this.templateFilter.types = [DETECTION_TYPES.pothole];
        break;
      case FilterType.interval:
        this.templateFilter.dateFrom = this._defaultDateFrom;
        this.templateFilter.dateTo = this._defaultDateTo;
        break;
      case FilterType.range:
        this.templateFilter.range = [0, 3000];
        break;
      case FilterType.confidence:
        this.templateFilter.confidence = [0, 1];
        break;
      case FilterType.status:
        this.templateFilter.status = this._getSatusArray();
        break;
      default:
      // code block
    }

    this.templateFilterSubject.next(this.templateFilter);
  }

  applytemplateFilterigChange() {
    this.templateFilterSubject.next(this.templateFilter);
  }
  private _getSatusArray(): Array<string> {
    const statusArr = Object.keys(DataStatus);
    const statusArrOfNum = statusArr.map(str => {
      return DataStatus[str];
    });
    return statusArrOfNum.filter(c => c != DataStatus.Discarded && c != DataStatus.Fixed);
  }

}
export interface ITemplateFilter {

  types: string[];                   // options: Dark, Light & Transparent
  dateFrom: any | undefined;         //{day:21, month:10, year:2022}
  dateTo: any | undefined;           //{day:21, month:10, year:2022}
  range: [number, number];
  confidence: [number, number];
  status: Array<string>;
  filter: any;
}
export enum FilterType {
  type = 0,
  range,
  interval,
  confidence,
  status
}
import { JsonpClientBackend } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import  *  as CryptoJS from  'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private key = "341";
  constructor(@Inject(SESSION_STORAGE) private storage: StorageService) { }

  public saveData(key: string, value: any) {
    const v = typeof value === 'object' && value !== null? JSON.stringify(value) : value;
    this.storage.set(key, this.encrypt(v));
  }
  public getData(key: string) {
    const res = this.storage.get(key);
    return res? JSON.parse(this.decrypt(res)) : null;
  }
  public removeData(key: string) {
    if(this.storage.get(key))this.storage.remove(key);
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString();
  }
  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
  }
}
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2
} from "@angular/core";

import { Subscription } from "rxjs";
import { ConfigService } from "@shared/services/config.service";
import { CustomizerService } from "@shared/services/customizer.service";
import { FilterService, FilterType, ITemplateFilter } from "@shared/services/filter.service";
import { DataStatus } from "@api-client/enum/data.enum"; 


@Component({
  selector: "app-filters",
  templateUrl:"./filters.component.html",
  styleUrls: ["./filters.component.scss"]
})
export class FiltersComponent implements OnInit, OnDestroy {
 
  @ViewChild("customizer") customizer: ElementRef;
  
  // public someRange: number[] = [0, 3000];
  // dateFrom;
  // dateTo;
  maxDate:any ={};
  date: {year: number, month: number};
  size: string;
  isBgImageDisplay: boolean = true;
  isOpen = true;
  public filter: ITemplateFilter = this.filterService.templateFilter;
  layoutSub: Subscription;
  public config: any = {};
  bgColor: string;
  statusItems:any = DataStatus;
  constructor(
    private customizerService: CustomizerService,
    private configService: ConfigService,
    private renderer: Renderer2,
    public filterService: FilterService,
  ) {
    const today = new Date();   
    this.maxDate = {year:today.getFullYear(), month:(today.getMonth()+1), day: today.getDate() };
    this.isOpen = !this.filter.filter.hidden;
    this.config = this.configService.templateConf;

    console.log('dateFrom',this.filter.dateFrom);
    console.log('dateTo',this.filter.dateTo);
  }

  @Output() directionEvent = new EventEmitter<Object>();

  ngOnInit() {
    //Set sidebar menu background color
    if (!this.config.layout.sidebar.backgroundColor) {
      this.bgColor = this.customizerService.light_dark_colors[7].code;
    } else {
      this.bgColor = this.config.layout.sidebar.backgroundColor;
    }
  }

  applyRangeFilter(ev){
      //this.filterService.applytemplateFilterigChange();
  }

  apply(){
    this.filterService.itemsCount = undefined;
    this.filterService.applytemplateFilterigChange()
  }
 
  resetFilter(t:FilterType){
    switch(t) {
   
      case FilterType.type:
        this.filterService.resetType(FilterType.type);
        break;
      case FilterType.interval:
        this.filterService.resetType(FilterType.interval); 
      // this.filter.dateFrom = undefined;
        // this.filter.dateTo = undefined;
        break;
      case FilterType.range:
        this.filterService.resetType(FilterType.range);
          break;
      case FilterType.confidence:
        this.filterService.resetType(FilterType.type);
        break;
      case FilterType.status:
        this.filterService.resetType(FilterType.status);
        break;
      default:
        // code block
    }

  }
  setDate(e?){
    //this.filterService.applytemplateFilterigChange();
  }
  toggleFilter() {
    if (this.isOpen) {
      this.renderer.removeClass(this.customizer.nativeElement, "open");
      this.isOpen = false;
    } else {
      this.renderer.addClass(this.customizer.nativeElement, "open");
      this.isOpen = true;
    }
  }

  closeFilter() {
    this.renderer.removeClass(this.customizer.nativeElement, "open");
    this.isOpen = false;
  }

  


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }


}

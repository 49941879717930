<div class="images-dialog bg-dark" *ngIf="isOpen" [ngClass]="{open: isOpen}" [@slideInOut]>
<div class="dialog-header">
<button ngbTooltip="{{'COMMON.CANCEL'|translate}}"  class="round btn btn-primary" (click)="close()"><span class="ft-x"></span></button>
<button  ngbTooltip="{{'IMAGES.CREATE_GROUP'|translate}}" class="round btn btn-primary ml-2" *ngIf="selectedItems.length > 1 && !element && authService.canEdit()" (click)="createGroup()" >
	<span class="badge bg-danger">{{selectedItems.length}}</span>
	<span class="ft-folder-plus"></span></button>
<!-- <button ngbTooltip="ungroup" class="round btn btn-primary ml-2" *ngIf="selectedItems.length > 1 && element" (click)="unGroup()" >
		<span class="badge bg-danger">{{selectedItems.length}}</span>
		<span class="ft-folder-minus"></span></button> -->
</div> 
<ng-container *ngIf="!element">
	<h3>{{'IMAGES.GROUP_CREATION'|translate}} {{items.length}} {{'IMAGES.ITEMS'|translate}}<br>
	<small>{{'IMAGES.GROUP_CREATION_HELP'|translate}}</small>
	</h3>
</ng-container>
<ng-container *ngIf="element">
	<h3>{{'IMAGES.TITLE'|translate}}({{items.length}})<br>
	<small>{{'IMAGES.DESCRIPTION'|translate}}</small>
	</h3>
</ng-container>
<section class="masonry" [perfectScrollbar]>
	<ngx-masonry [options]="masonryOptions" [ordered]="true">
		<div  [ngClass]="{'img-selected': isSelected(item)}" class="masonry-item" ngxMasonryItem *ngFor="let item of items"  (click)="addSelection(item)">
			<div class="triangle-top-right"><span class="ft-check"></span></div>
			<button  ngbTooltip="{{'IMAGES.UNGROUP'|translate}}" placement="bottom" *ngIf="element && authService.canEdit()" class="round btn btn-danger ml-2" (click)="unGroup(item)" ><span class="ft-eye-off"></span></button>
			 <img masonryLazy loading="lazy" alt="" width="100%" [src]="getImg(item.image)" /> 
            <!-- <img alt="" [src]="item.image" width="100%"/> -->
		</div>
	</ngx-masonry>
</section>
</div>
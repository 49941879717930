/**
 * KEYE-API
 * API for KEYE portal
 *
 * OpenAPI spec version: 2022-12-19
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface GetTrackingRequest { 
    devices?: Array<string>;
    start_time?: number;
    end_time?: number;
    lon_min?: number;
    lat_min?: number;
    lon_max?: number;
    lat_max?: number;
}

import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  // {
  //   path: '/dashboard', title: 'Dashboard', icon: 'icon-pie-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: '/maps', title: 'NAVBAR.MENU_ITEMS.MAP', icon: 'icon-map', class: '', badge: '', badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/list', title: 'NAVBAR.MENU_ITEMS.LIST', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '',
    isExternalLink: false,
    submenu: []
  },
  {
    path: '/dashboard', title: 'NAVBAR.MENU_ITEMS.DASHBOARDS', icon: 'icon-bar-chart', class: '', badge: '', badgeClass: '',
    isExternalLink: false,
    submenu: [{
      path: '/dashboard/potholes',
      title: 'NAVBAR.SUB_MENU_ITEMS.POTHOLES',
      icon: '',
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: []
    },
    {
      path: '/dashboard/sectors',
      title: 'NAVBAR.SUB_MENU_ITEMS.SECTORS',
      icon: '',
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: []
    }]
  },
  {
    path: '/pages/users-list', title: 'NAVBAR.MENU_ITEMS.USERS', icon: 'icon-settings', class: '', badge: '', badgeClass: '',
    isExternalLink: false,
    isAdmin: true,
    submenu: []
  }
];

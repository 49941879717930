import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
declare var window: any;

@Injectable()
export class AppInitService {
  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  public init = async () => {
    const _path = "assets/app-config.json";
    
    return from(
      fetch(_path).then(function (response) {
        return response.json();
      })
    )
      .pipe(
        map((config) => {
          window.config = config;
          return;
        })
      )
      .toPromise();
  };
}

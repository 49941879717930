/**
 * KEYE-API
 * API for KEYE portal
 *
 * OpenAPI spec version: 2022-12
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { ChangeEntityStatusRequest } from '../model/changeEntityStatusRequest';
import { CreateUserRequest } from '../model/createUserRequest';
import { DeleteUserRequest } from '../model/deleteUserRequest';
import { DisableDeviceRequest } from '../model/disableDeviceRequest';
import { Empty } from '../model/empty';
import { GetDensityResponse } from '../model/getDensityResponse';
import { GetDetectionRequest } from '../model/getDetectionRequest';
import { GetDetectionResponse } from '../model/getDetectionResponse';
import { GetDeviceResponse } from '../model/getDeviceResponse';
import { GetEntityGroupResponse } from '../model/getEntityGroupResponse';
import { GetOrganizationResponse } from '../model/getOrganizationResponse';
import { GetPieResponse } from '../model/getPieResponse';
import { GetTrackingRequest } from '../model/getTrackingRequest';
import { GetTrackingResponse } from '../model/getTrackingResponse';
import { GetUserNotificationsResponse } from '../model/getUserNotificationsResponse';
import { GetUserResponse } from '../model/getUserResponse';
import { GroupDetectionsRequest } from '../model/groupDetectionsRequest';
import { UngroupDetectionRequest } from '../model/ungroupDetectionRequest';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { GetLocationReverseGeocodeResponse } from '@api-client/model/getLocationReverseGeocodeResponse';
import { GetTotalSelectedRequest } from '@api-client/model/getTotalSelectedRequest';
import { GetTotalSelectedResponse } from '@api-client/model/getTotalSelectedResponse';
import { GetAggregateSelectedRequest } from '@api-client/model/getAggregateSelectedRequest';
import { GetAggregateSelectedResponse } from '@api-client/model/getAggregateSelectedResponse';
import { GenericFilteredRequest } from '@api-client/model/genericFilteredRequest';
import { GetStatusChangesResponse } from '@api-client/model/getStatusChangesResponse';
import { GetNewDetectionsResponse } from '@api-client/model/getNewDetectionsResponse';
import { GetTotalActiveResponse } from '@api-client/model/getTotalActiveResponse';
import { GetSelectedStatusOverTotalResponse } from '@api-client/model/getSelectedStatusOverTotalResponse';
import { GetSelectedCategoryOverTotalResponse } from '@api-client/model/getSelectedCategoryOverTotalResponse';
import { GetDensityOnVisitedResponse } from '@api-client/model/getDensityOnVisitedResponse';
import { getOrganizationSessionsResponse } from '@api-client/model/getOrganizationSessionsResponse';
import { getSessionsStatsDataResponse } from '@api-client/model/getSessionsStatsDataResponse';
import { GetSectionEntitiesResponse } from '@api-client/model/getSectionsEntitiesResponse';
import { getSectionsEntitiesRequest } from '@api-client/model/getSectionsEntitiesRequest';
import { getSectionsStatsDataRequest } from '@api-client/model/getSectionsStatsDataRequest';
import { GetDetectionResponseItems } from '@api-client/model/models';


@Injectable()
export class DefaultService {

    protected basePath = 'https://dx5hnrf6n2.execute-api.eu-west-1.amazonaws.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param empty 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminDevicesGet(empty: Empty, observe?: 'body', reportProgress?: boolean): Observable<GetDeviceResponse>;
    public adminDevicesGet(empty: Empty, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDeviceResponse>>;
    public adminDevicesGet(empty: Empty, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDeviceResponse>>;
    public adminDevicesGet(empty: Empty, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (empty === null || empty === undefined) {
            throw new Error('Required parameter empty was null or undefined when calling adminDevicesGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<GetDeviceResponse>(`${this.basePath}/admin/devices`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminDevicesOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminDevicesOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminDevicesOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminDevicesOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/devices`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param disableDeviceRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminDevicesPost(disableDeviceRequest: DisableDeviceRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminDevicesPost(disableDeviceRequest: DisableDeviceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminDevicesPost(disableDeviceRequest: DisableDeviceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminDevicesPost(disableDeviceRequest: DisableDeviceRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (disableDeviceRequest === null || disableDeviceRequest === undefined) {
            throw new Error('Required parameter disableDeviceRequest was null or undefined when calling adminDevicesPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/admin/devices`,
            disableDeviceRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deleteUserRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersDelete(deleteUserRequest: DeleteUserRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUsersDelete(deleteUserRequest: DeleteUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUsersDelete(deleteUserRequest: DeleteUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUsersDelete(deleteUserRequest: DeleteUserRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (deleteUserRequest === null || deleteUserRequest === undefined) {
            throw new Error('Required parameter deleteUserRequest was null or undefined when calling adminUsersDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.delete<Empty>(`${this.basePath}/admin/users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                body: deleteUserRequest
            },

        );
    }

    /**
     * 
     * 
     * @param empty 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersGet(empty: Empty, observe?: 'body', reportProgress?: boolean): Observable<GetUserResponse>;
    public adminUsersGet(empty: Empty, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserResponse>>;
    public adminUsersGet(empty: Empty, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserResponse>>;
    public adminUsersGet(empty: Empty, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (empty === null || empty === undefined) {
            throw new Error('Required parameter empty was null or undefined when calling adminUsersGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<GetUserResponse>(`${this.basePath}/admin/users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUsersOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUsersOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUsersOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param createUserRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersPost(createUserRequest: CreateUserRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUsersPost(createUserRequest: CreateUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUsersPost(createUserRequest: CreateUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUsersPost(createUserRequest: CreateUserRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (createUserRequest === null || createUserRequest === undefined) {
            throw new Error('Required parameter createUserRequest was null or undefined when calling adminUsersPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/admin/users`,
            createUserRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionDataOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public detectionDataOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public detectionDataOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public detectionDataOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/detection/data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param getDetectionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionDataPost(getDetectionRequest: GetDetectionRequest, observe?: 'body', reportProgress?: boolean): Observable<GetDetectionResponse>;
    public detectionDataPost(getDetectionRequest: GetDetectionRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDetectionResponse>>;
    public detectionDataPost(getDetectionRequest: GetDetectionRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDetectionResponse>>;
    public detectionDataPost(getDetectionRequest: GetDetectionRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (getDetectionRequest === null || getDetectionRequest === undefined) {
            throw new Error('Required parameter getDetectionRequest was null or undefined when calling detectionDataPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GetDetectionResponse>(`${this.basePath}/detection/data`,
            getDetectionRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param entityUUID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionGroupGet(entityUUID: string, observe?: 'body', reportProgress?: boolean): Observable<GetEntityGroupResponse>;
    public detectionGroupGet(entityUUID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEntityGroupResponse>>;
    public detectionGroupGet(entityUUID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEntityGroupResponse>>;
    public detectionGroupGet(entityUUID: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (entityUUID === null || entityUUID === undefined) {
            throw new Error('Required parameter entityUUID was null or undefined when calling detectionGroupGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (entityUUID !== undefined && entityUUID !== null) {
            queryParameters = queryParameters.set('entityUUID', <any>entityUUID);
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetEntityGroupResponse>(`${this.basePath}/detection/group`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionGroupOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public detectionGroupOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public detectionGroupOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public detectionGroupOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/detection/group`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param groupDetectionsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionGroupPost(groupDetectionsRequest: GroupDetectionsRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public detectionGroupPost(groupDetectionsRequest: GroupDetectionsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public detectionGroupPost(groupDetectionsRequest: GroupDetectionsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public detectionGroupPost(groupDetectionsRequest: GroupDetectionsRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (groupDetectionsRequest === null || groupDetectionsRequest === undefined) {
            throw new Error('Required parameter groupDetectionsRequest was null or undefined when calling detectionGroupPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/detection/group`,
            groupDetectionsRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionImageOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public detectionImageOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public detectionImageOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public detectionImageOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/detection/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param token 
     * @param proxy 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionImageProxyGet(token: string, proxy: string, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public detectionImageProxyGet(token: string, proxy: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public detectionImageProxyGet(token: string, proxy: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public detectionImageProxyGet(token: string, proxy: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling detectionImageProxyGet.');
        }

        if (proxy === null || proxy === undefined) {
            throw new Error('Required parameter proxy was null or undefined when calling detectionImageProxyGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'image/jpeg'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Empty>(`${this.basePath}/detection/image/${encodeURIComponent(String(proxy))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param proxy 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionImageProxyOptions(proxy: string, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public detectionImageProxyOptions(proxy: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public detectionImageProxyOptions(proxy: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public detectionImageProxyOptions(proxy: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (proxy === null || proxy === undefined) {
            throw new Error('Required parameter proxy was null or undefined when calling detectionImageProxyOptions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/detection/image/${encodeURIComponent(String(proxy))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionStatusOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public detectionStatusOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public detectionStatusOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public detectionStatusOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/detection/status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param changeEntityStatusRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionStatusPost(changeEntityStatusRequest: ChangeEntityStatusRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public detectionStatusPost(changeEntityStatusRequest: ChangeEntityStatusRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public detectionStatusPost(changeEntityStatusRequest: ChangeEntityStatusRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public detectionStatusPost(changeEntityStatusRequest: ChangeEntityStatusRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (changeEntityStatusRequest === null || changeEntityStatusRequest === undefined) {
            throw new Error('Required parameter changeEntityStatusRequest was null or undefined when calling detectionStatusPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/detection/status`,
            changeEntityStatusRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionUngroupOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public detectionUngroupOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public detectionUngroupOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public detectionUngroupOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/detection/ungroup`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param ungroupDetectionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectionUngroupPost(ungroupDetectionRequest: UngroupDetectionRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public detectionUngroupPost(ungroupDetectionRequest: UngroupDetectionRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public detectionUngroupPost(ungroupDetectionRequest: UngroupDetectionRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public detectionUngroupPost(ungroupDetectionRequest: UngroupDetectionRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (ungroupDetectionRequest === null || ungroupDetectionRequest === undefined) {
            throw new Error('Required parameter ungroupDetectionRequest was null or undefined when calling detectionUngroupPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/detection/ungroup`,
            ungroupDetectionRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
   /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
   public kpiAggregateOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
   public kpiAggregateOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
   public kpiAggregateOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
   public kpiAggregateOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

       let headers = this.defaultHeaders;

       // to determine the Accept header
       let httpHeaderAccepts: string[] = [
           'application/json'
       ];
       const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected != undefined) {
           headers = headers.set('Accept', httpHeaderAcceptSelected);
       }

       // to determine the Content-Type header
       const consumes: string[] = [
           'application/json'
       ];

       return this.httpClient.options<Empty>(`${this.basePath}/kpi/aggregate`,
           {
               withCredentials: this.configuration.withCredentials,
               headers: headers,
               observe: observe,
               reportProgress: reportProgress
           }
       );
   }

   /**
    * 
    * 
    * @param getAggregateSelectedRequest 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
   public kpiAggregatePost(getAggregateSelectedRequest: GetAggregateSelectedRequest, observe?: 'body', reportProgress?: boolean): Observable<GetAggregateSelectedResponse>;
   public kpiAggregatePost(getAggregateSelectedRequest: GetAggregateSelectedRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetAggregateSelectedResponse>>;
   public kpiAggregatePost(getAggregateSelectedRequest: GetAggregateSelectedRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetAggregateSelectedResponse>>;
   public kpiAggregatePost(getAggregateSelectedRequest: GetAggregateSelectedRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

       if (getAggregateSelectedRequest === null || getAggregateSelectedRequest === undefined) {
           throw new Error('Required parameter getAggregateSelectedRequest was null or undefined when calling kpiAggregatePost.');
       }

       let headers = this.defaultHeaders;

       // authentication (DEV-KEYE-PORTAL-USERS) required
       if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
           headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
       }

       // to determine the Accept header
       let httpHeaderAccepts: string[] = [
           'application/json'
       ];
       const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected != undefined) {
           headers = headers.set('Accept', httpHeaderAcceptSelected);
       }

       // to determine the Content-Type header
       const consumes: string[] = [
           'application/json'
       ];
       const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
       if (httpContentTypeSelected != undefined) {
           headers = headers.set('Content-Type', httpContentTypeSelected);
       }

       return this.httpClient.post<GetAggregateSelectedResponse>(`${this.basePath}/kpi/aggregate`,
           getAggregateSelectedRequest,
           {
               withCredentials: this.configuration.withCredentials,
               headers: headers,
               observe: observe,
               reportProgress: reportProgress
           }
       );
   }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kpiDensityGet(observe?: 'body', reportProgress?: boolean): Observable<GetDensityResponse>;
    public kpiDensityGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDensityResponse>>;
    public kpiDensityGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDensityResponse>>;
    public kpiDensityGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDensityResponse>(`${this.basePath}/kpi/density`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kpiDensityOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public kpiDensityOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public kpiDensityOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public kpiDensityOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/kpi/density`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * 
     * 
     * @param endTime 
     * @param startTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kpiDensityVisitedGet(endTime: string, startTime: string, observe?: 'body', reportProgress?: boolean): Observable<GetDensityResponse>;
    public kpiDensityVisitedGet(endTime: string, startTime: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDensityResponse>>;
    public kpiDensityVisitedGet(endTime: string, startTime: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDensityResponse>>;
    public kpiDensityVisitedGet(endTime: string, startTime: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (endTime === null || endTime === undefined) {
            throw new Error('Required parameter endTime was null or undefined when calling kpiDensityVisitedGet.');
        }

        if (startTime === null || startTime === undefined) {
            throw new Error('Required parameter startTime was null or undefined when calling kpiDensityVisitedGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (endTime !== undefined && endTime !== null) {
            queryParameters = queryParameters.set('end_time', <any>endTime);
        }
        if (startTime !== undefined && startTime !== null) {
            queryParameters = queryParameters.set('start_time', <any>startTime);
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDensityResponse>(`${this.basePath}/kpi/density-visited`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kpiDensityVisitedOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public kpiDensityVisitedOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public kpiDensityVisitedOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public kpiDensityVisitedOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/kpi/density-visited`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
 /**
     * 
     * 
     * @param genericFilteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
 public kpiDensityVisitedPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<GetDensityOnVisitedResponse>;
 public kpiDensityVisitedPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDensityOnVisitedResponse>>;
 public kpiDensityVisitedPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDensityOnVisitedResponse>>;
 public kpiDensityVisitedPost(genericFilteredRequest: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

     if (genericFilteredRequest === null || genericFilteredRequest === undefined) {
         throw new Error('Required parameter genericFilteredRequest was null or undefined when calling kpiDensityVisitedPost.');
     }

     let headers = this.defaultHeaders;

     // authentication (DEV-KEYE-PORTAL-USERS) required
     if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
         headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
     }

     // to determine the Accept header
     let httpHeaderAccepts: string[] = [
         'application/json'
     ];
     const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
     if (httpHeaderAcceptSelected != undefined) {
         headers = headers.set('Accept', httpHeaderAcceptSelected);
     }

     // to determine the Content-Type header
     const consumes: string[] = [
         'application/json'
     ];
     const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
     if (httpContentTypeSelected != undefined) {
         headers = headers.set('Content-Type', httpContentTypeSelected);
     }

     return this.httpClient.post<GetDensityOnVisitedResponse>(`${this.basePath}/kpi/density-visited`,
         genericFilteredRequest,
         {
             withCredentials: this.configuration.withCredentials,
             headers: headers,
             observe: observe,
             reportProgress: reportProgress
         }
     );
 }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kpiPieGet(observe?: 'body', reportProgress?: boolean): Observable<GetPieResponse>;
    public kpiPieGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetPieResponse>>;
    public kpiPieGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetPieResponse>>;
    public kpiPieGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetPieResponse>(`${this.basePath}/kpi/pie`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kpiPieOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public kpiPieOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public kpiPieOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public kpiPieOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/kpi/pie`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
    * 
    * 
    * @param getTotalSelectedRequest 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public kpiPiePost(getTotalSelectedRequest: GetTotalSelectedRequest, observe?: 'body', reportProgress?: boolean): Observable<GetTotalSelectedResponse>;
    public kpiPiePost(getTotalSelectedRequest: GetTotalSelectedRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetTotalSelectedResponse>>;
    public kpiPiePost(getTotalSelectedRequest: GetTotalSelectedRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetTotalSelectedResponse>>;
    public kpiPiePost(getTotalSelectedRequest: GetTotalSelectedRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (getTotalSelectedRequest === null || getTotalSelectedRequest === undefined) {
            throw new Error('Required parameter getTotalSelectedRequest was null or undefined when calling kpiPiePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GetTotalSelectedResponse>(`${this.basePath}/kpi/pie`,
            getTotalSelectedRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kpiSelectedOverTotalCategoryOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public kpiSelectedOverTotalCategoryOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public kpiSelectedOverTotalCategoryOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public kpiSelectedOverTotalCategoryOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/kpi/selected-over-total/category`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param genericFilteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kpiSelectedOverTotalCategoryPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<GetSelectedCategoryOverTotalResponse>;
    public kpiSelectedOverTotalCategoryPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetSelectedCategoryOverTotalResponse>>;
    public kpiSelectedOverTotalCategoryPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetSelectedCategoryOverTotalResponse>>;
    public kpiSelectedOverTotalCategoryPost(genericFilteredRequest: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (genericFilteredRequest === null || genericFilteredRequest === undefined) {
            throw new Error('Required parameter genericFilteredRequest was null or undefined when calling kpiSelectedOverTotalCategoryPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GetSelectedCategoryOverTotalResponse>(`${this.basePath}/kpi/selected-over-total/category`,
            genericFilteredRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kpiSelectedOverTotalStatusOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public kpiSelectedOverTotalStatusOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public kpiSelectedOverTotalStatusOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public kpiSelectedOverTotalStatusOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/kpi/selected-over-total/status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param genericFilteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kpiSelectedOverTotalStatusPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<GetSelectedStatusOverTotalResponse>;
    public kpiSelectedOverTotalStatusPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetSelectedStatusOverTotalResponse>>;
    public kpiSelectedOverTotalStatusPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetSelectedStatusOverTotalResponse>>;
    public kpiSelectedOverTotalStatusPost(genericFilteredRequest: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (genericFilteredRequest === null || genericFilteredRequest === undefined) {
            throw new Error('Required parameter genericFilteredRequest was null or undefined when calling kpiSelectedOverTotalStatusPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GetSelectedStatusOverTotalResponse>(`${this.basePath}/kpi/selected-over-total/status`,
            genericFilteredRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    * 
    * 
    * @param lat 
    * @param lon 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public locationInverseGeocodeGet(lat: string, lon: string, observe?: 'body', reportProgress?: boolean): Observable<GetLocationReverseGeocodeResponse>;
    public locationInverseGeocodeGet(lat: string, lon: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetLocationReverseGeocodeResponse>>;
    public locationInverseGeocodeGet(lat: string, lon: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetLocationReverseGeocodeResponse>>;
    public locationInverseGeocodeGet(lat: string, lon: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (lat === null || lat === undefined) {
            throw new Error('Required parameter lat was null or undefined when calling locationInverseGeocodeGet.');
        }

        if (lon === null || lon === undefined) {
            throw new Error('Required parameter lon was null or undefined when calling locationInverseGeocodeGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (lat !== undefined && lat !== null) {
            queryParameters = queryParameters.set('lat', <any>lat);
        }
        if (lon !== undefined && lon !== null) {
            queryParameters = queryParameters.set('lon', <any>lon);
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GetLocationReverseGeocodeResponse>(`${this.basePath}/location/inverse-geocode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
       * 
       * 
       * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
       * @param reportProgress flag to report request and response progress.
       */
    public locationInverseGeocodeOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public locationInverseGeocodeOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public locationInverseGeocodeOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public locationInverseGeocodeOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/location/inverse-geocode`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public locationOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public locationOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public locationOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/location`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationGet(observe?: 'body', reportProgress?: boolean): Observable<GetOrganizationResponse>;
    public organizationGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOrganizationResponse>>;
    public organizationGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOrganizationResponse>>;
    public organizationGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetOrganizationResponse>(`${this.basePath}/organization`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public organizationOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public organizationOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public organizationOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/organization`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public organizationSections(withGeo: boolean, observe?: 'body', reportProgress?: boolean): Observable<getOrganizationSessionsResponse[]>;
     public organizationSections(withGeo: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<getOrganizationSessionsResponse[]>>;
     public organizationSections(withGeo: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<getOrganizationSessionsResponse[]>>;
     public organizationSections(withGeo: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (withGeo === null || withGeo === undefined) {
            throw new Error('Required parameter withGeo was null or undefined when calling organizationSections.');
        }

         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
         let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
         if (withGeo !== undefined && withGeo !== null) {
            queryParameters = queryParameters.set('with_geo', <any>withGeo);
        }
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
 
         return this.httpClient.get<Empty>(`${this.basePath}/organization/sections`,
             {
                params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

      /**
     * 
     * 
     * @param genericFilteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sectionsStatsDataPost(genericFilteredRequest: getSectionsStatsDataRequest, observe?: 'body', reportProgress?: boolean): Observable<getSessionsStatsDataResponse>;
    public sectionsStatsDataPost(genericFilteredRequest: getSectionsStatsDataRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<getSessionsStatsDataResponse>>;
    public sectionsStatsDataPost(genericFilteredRequest: getSectionsStatsDataRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<getSessionsStatsDataResponse>>;
    public sectionsStatsDataPost(genericFilteredRequest: getSectionsStatsDataRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (genericFilteredRequest === null || genericFilteredRequest === undefined) {
            throw new Error('Required parameter getSectionsStatsDataRequest was null or undefined when calling sectionsStatsDataPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GetNewDetectionsResponse>(`${this.basePath}/sections/stats/data`,
            genericFilteredRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

      /**
     * 
     * 
     * @param filteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
      public sectionsStatsEntitiesPost(filteredRequest: getSectionsEntitiesRequest, observe?: 'body', reportProgress?: boolean): Observable<GetDetectionResponseItems[]>;
      public sectionsStatsEntitiesPost(filteredRequest: getSectionsEntitiesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDetectionResponseItems[]>>;
      public sectionsStatsEntitiesPost(filteredRequest: getSectionsEntitiesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDetectionResponseItems[]>>;
      public sectionsStatsEntitiesPost(filteredRequest: getSectionsEntitiesRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
  
          if (filteredRequest === null || filteredRequest === undefined) {
              throw new Error('Required parameter filteredRequest was null or undefined when calling sectionsStatsEntitiesPost.');
          }
  
          let headers = this.defaultHeaders;
  
          // authentication (DEV-KEYE-PORTAL-USERS) required
          if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
              headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
          }
  
          // to determine the Accept header
          let httpHeaderAccepts: string[] = [
              'application/json'
          ];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
              headers = headers.set('Accept', httpHeaderAcceptSelected);
          }
  
          // to determine the Content-Type header
          const consumes: string[] = [
              'application/json'
          ];
          const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
          if (httpContentTypeSelected != undefined) {
              headers = headers.set('Content-Type', httpContentTypeSelected);
          }
  
          return this.httpClient.post<GetNewDetectionsResponse>(`${this.basePath}/sections/stats/entities`,
          filteredRequest,
              {
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }

    /**
    * 
    * 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public statsNewDetectionsOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public statsNewDetectionsOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public statsNewDetectionsOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public statsNewDetectionsOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/stats/new-detections`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param genericFilteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsNewDetectionsPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<GetNewDetectionsResponse>;
    public statsNewDetectionsPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetNewDetectionsResponse>>;
    public statsNewDetectionsPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetNewDetectionsResponse>>;
    public statsNewDetectionsPost(genericFilteredRequest: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (genericFilteredRequest === null || genericFilteredRequest === undefined) {
            throw new Error('Required parameter genericFilteredRequest was null or undefined when calling statsNewDetectionsPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GetNewDetectionsResponse>(`${this.basePath}/stats/new-detections`,
            genericFilteredRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
        * 
        * 
        * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
        * @param reportProgress flag to report request and response progress.
        */
    public statsStatusChangesOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public statsStatusChangesOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public statsStatusChangesOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public statsStatusChangesOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/stats/status-changes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param genericFilteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsStatusChangesPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<GetStatusChangesResponse>;
    public statsStatusChangesPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetStatusChangesResponse>>;
    public statsStatusChangesPost(genericFilteredRequest: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetStatusChangesResponse>>;
    public statsStatusChangesPost(genericFilteredRequest: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (genericFilteredRequest === null || genericFilteredRequest === undefined) {
            throw new Error('Required parameter genericFilteredRequest was null or undefined when calling statsStatusChangesPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GetStatusChangesResponse>(`${this.basePath}/stats/status-changes`,
            genericFilteredRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsTotalActiveOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public statsTotalActiveOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public statsTotalActiveOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public statsTotalActiveOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/stats/total-active`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param genericFilteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsTotalActivePost(genericFilteredRequest: GenericFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<GetTotalActiveResponse>;
    public statsTotalActivePost(genericFilteredRequest: GenericFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetTotalActiveResponse>>;
    public statsTotalActivePost(genericFilteredRequest: GenericFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetTotalActiveResponse>>;
    public statsTotalActivePost(genericFilteredRequest: GenericFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (genericFilteredRequest === null || genericFilteredRequest === undefined) {
            throw new Error('Required parameter genericFilteredRequest was null or undefined when calling statsTotalActivePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GetTotalActiveResponse>(`${this.basePath}/stats/total-active`,
            genericFilteredRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trackingOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public trackingOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public trackingOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public trackingOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/tracking`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param getTrackingRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trackingPost(getTrackingRequest: GetTrackingRequest, observe?: 'body', reportProgress?: boolean): Observable<GetTrackingResponse>;
    public trackingPost(getTrackingRequest: GetTrackingRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetTrackingResponse>>;
    public trackingPost(getTrackingRequest: GetTrackingRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetTrackingResponse>>;
    public trackingPost(getTrackingRequest: GetTrackingRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (getTrackingRequest === null || getTrackingRequest === undefined) {
            throw new Error('Required parameter getTrackingRequest was null or undefined when calling trackingPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GetTrackingResponse>(`${this.basePath}/tracking`,
            getTrackingRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userNotificationsGet(observe?: 'body', reportProgress?: boolean): Observable<GetUserNotificationsResponse>;
    public userNotificationsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserNotificationsResponse>>;
    public userNotificationsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserNotificationsResponse>>;
    public userNotificationsGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (KEYE-PORTAL-USERS) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetUserNotificationsResponse>(`${this.basePath}/user/notifications`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userNotificationsOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public userNotificationsOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public userNotificationsOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public userNotificationsOptions(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/user/notifications`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { environment } from 'environments/environment';
import Amplify, { Auth } from 'aws-amplify';
import { LocalStorageService } from '../services/storage.service';
import { TOKEN_IMG_KEY, TOKEN_KEY, USER_KEY } from '@shared/constants/app.constants';
import { catchError, map } from 'rxjs/operators';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthService {
  private cognitoConfig;
  private session;
  public authenticationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 
  private decodedToken: any| null;

  //###############
  //notify
  private notifyRequest = new ReplaySubject<AuthNotification>();
  notifyRequest$ = this.notifyRequest.asObservable();
  notify(notification: AuthNotification) {
    this.notifyRequest.next(notification);
  }
  //################
  //end notify

  constructor(public toastr: ToastrService,private localStorageService:LocalStorageService) {
    this.cognitoConfig = Amplify.configure({
      Auth: environment.cognitoConfig
    });
    const _token = this.localStorageService.getData(TOKEN_KEY);
    if(_token){
      this.decodedToken = _token; 
      this._setSession();
     //this.getUserasync();
      // if(this.session){
      //   const refresh_token = this.session.getRefreshToken();
        
      // }
    }else 
    this.authenticationSubject.next(false);
  }
  private async getUserasync(){
    const usr = await this.getUser();
    this.user = usr;
  }
  public getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }
  private async _setSession(){
    try
    {
    const session = await Auth.currentSession().then((s)=>{
    this.session = s;
    const tk = s.getIdToken();
    this.token = ['Bearer',tk["jwtToken"]].join(' ');
    this.tokenImg = tk["jwtToken"];
    //s.isValid()
      // console.log("token",s.getAccessToken());
      // console.log("refresh-token",s.getRefreshToken());
    }).catch(err=>{
      this.authenticationSubject.next(false); 
      console.log(err)
    })
      //this.signOut();
    //let decoded = jwt_decode(user.signInUserSession.idToken.jwtToken);
    //this.decodedToken=decoded;
    
    //console.log(this.decodedToken.email);
    
    }
    catch(error)
    {
        console.log(error);
        this.signOut();
    }
  }
  public async forgotPassword(username:string){
    const res = await Auth.forgotPassword(username);
    return  res;    
  // .then((data) => console.log(data))
  // .catch((err) => console.log(err));
  }
  public async forgotPasswordSubmit(username:string, code:string, new_password:string){
    const res = await Auth.forgotPasswordSubmit(username, code, new_password); 
    return  res;    
  }
  

  public async loginChangePassword(oldPassword:string, newPassword:string)
  {
    const user = this.user;
    const username = user.username;
    const changePsw = await Auth.signIn(username, oldPassword)
    .then((user) => {
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      //const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
    return Auth.completeNewPassword(
        user, // the Cognito User Object
        newPassword, // the new password
        // OPTIONAL, the required attributes
        {}
      )
        .then((user) => {
          // at this time the user is logged in if no MFA required
          this.user = user;
          this._setSession();
          this.notify({
            message:'',
            title:'',
            type:AuthNotificationType.success
          })
          
        })
        .catch((err) => {
          console.log(err)
          this.notify({
            message:err,
            title:'',
            type:AuthNotificationType.danger
          })
        });
    } 
    })
    .catch((e) => {
      console.log(e);
      this.notify({
        message:e,
        title:'',
        type:AuthNotificationType.danger
      })
    });
  }
  public async signIn(email:string, password:string)
  {
    const user = await Auth.signIn(String(email), String(password));
    

    const s = await this._setSession();
    this.user = user;
    return user;
  }
  public async changePassword(oldPassword:string, password:string){
    const user = this.user;
    const changePsw = Auth.changePassword(user, oldPassword, password)
    .then((cpw) => {
      return {
        auth: cpw,
        isError:false,
        msg:undefined
      };
    })
    .then((data) => 
            console.log(data)
          )
    .catch((err) => {
                console.log(err)
                return {
                  auth: undefined,
                  isError:true,
                  msg:err
                };
                
              }
          );
    return changePsw;
  }
  
  getExpiryTime() {
    if(this.decodedToken)
    this.decodedToken = this.localStorageService.getData(TOKEN_KEY) || null;
    return this.decodedToken ? this.decodedToken.exp : null;
  }
  

  // isTokenExpired(): boolean {
  //   const expiryTime: number = this.getExpiryTime();
  //   console.log('expiryTime :' +expiryTime);
  //   if (expiryTime) {
  //       var d = new Date(expiryTime*1000); // The 0 there is the key, which sets the date to the epoch      
  //     return (d.getTime() - (new Date()).getTime()) < 5000;
  //   } else {
  //     return true;
  //   }
  // }
  isTokenExpired(): boolean {
    return !this.session || !this.session.isValid()? true :false;
  }



  public async onConfirmSignUp(userName:string, code: string)
  {
      return  await Auth.confirmSignUp(userName, code);
  }


  public signOut(): Promise<any> {
    this.localStorageService.removeData(TOKEN_KEY);
    this.decodedToken=null;
    const _self = this;
    return Auth.signOut({global:true})
    .then(() => {
      _self.authenticationSubject.next(false);
    });
  }
  public changePasswordReuired():boolean{
    return this.user ? this.user.challengeName === 'NEW_PASSWORD_REQUIRED' : false;
  }
  public isAuthenticated(): Observable<boolean> {
    const _self = this;
    if(_self.authenticationSubject === undefined || !this.token)
     {
      _self.authenticationSubject = new BehaviorSubject<boolean>(false); 
      return of(false)
     }
     else
      return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          _self.authenticationSubject.next(true);
          return true;
        }),
        catchError(error => {
          _self.authenticationSubject.next(false);
          return of(false);
        })
      );
  }

  public getAuthImgPath(path:string):string{
    return [path, '?token=', this.tokenImg].join('');
  }
  public isAdmin():boolean{
    if(this.user){
      return this.user.attributes["custom:role"] === "ADMIN" || this.user.attributes["custom:Role"] === "ADMIN" 
    }
    return false;
  }
  public canEdit():boolean{
    if(this.user){
      return this.user.attributes["custom:role"] != "READER" && this.user.attributes["custom:Role"] != "READER" 
    }
    return false;
  }

  set token(tk){
    const obj = {token: tk };
    this.localStorageService.saveData(TOKEN_KEY, obj)
  }
  get token(){
    const tk =  this.localStorageService.getData(TOKEN_KEY);
    return tk? tk.token : null;
  }
  set tokenImg(tk){
    const obj = {token: tk };
    this.localStorageService.saveData(TOKEN_IMG_KEY, obj)
  }
  get tokenImg(){
    const tk =  this.localStorageService.getData(TOKEN_IMG_KEY);
    return tk? tk.token : null;
  }
  set user(k){
    this.localStorageService.saveData(USER_KEY, k)
  }
  get user(){
    const k =  this.localStorageService.getData(USER_KEY);
    return k? k: null;
  }
  
}

export enum AuthNotificationType {
  success = 'is-success',
  danger = 'is-danger',
  warning = 'is-warning',
}

export interface AuthNotification {
  title: string;
  message: string;
  type: AuthNotificationType;
}
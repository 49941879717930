

       
<ng-container *ngIf="(!editable && element) || !canEdit">

    
    <div class="lbl-status-badge" [ngClass]="{'large': large}" >
        <ng-container [ngSwitch]="element.status">
             <!-- Detected -->
                <span *ngSwitchCase="statusEnum.Detected" 
                class="badge bg-light-secondary round">
                <i  class="fa fa-check-circle secondary font-small-3 mr-1"></i>
                {{getStatusLbl()}}</span>

            <!-- Assigned -->
                <span *ngSwitchCase="statusEnum.Assigned" 
                class="badge bg-light-primary round">
                <i  class="fa fa-check-circle  primary font-small-3 mr-1"></i>
                {{getStatusLbl()}}</span>
            <!-- InProgress -->
                <span *ngSwitchCase="statusEnum.InProgress" 
                class="badge bg-light-warning round">
                <i  class="fa fa-spinner spinner  warning font-small-3 mr-1"></i>
                {{getStatusLbl()}}</span>

            <!-- Repaired, Fixed -->
                <span *ngSwitchCase="statusEnum.Fixed" 
                class="badge bg-light-success round">
                <i  class="fa fa-check-circle success font-small-3 mr-1"></i>
                {{getStatusLbl()}}</span>
            <!-- Discarded, Fixed -->
                 <span *ngSwitchCase="statusEnum.Discarded" 
                 class="badge bg-light-danger round">
                 <i  class="fa fa-times-circle font-small-3 mr-1 "></i>
                 {{getStatusLbl()}}</span>
                 
		</ng-container>
    </div>
</ng-container>
<ng-container *ngIf="editable && element && canEdit">
    <div ngbDropdown>
    <div id="ddStatus" ngbDropdownToggle class="lbl-status-badge" [ngClass]="{'large': large}" >
        <ng-container [ngSwitch]="element.status">
             <!-- Detected -->
                <span *ngSwitchCase="statusEnum.Detected" 
                class="badge bg-light-secondary round">
                <i  class="fa fa-check-circle secondary font-small-3 mr-1"></i>
                {{getStatusLbl()}}</span>

            <!-- Assigned -->
                <span *ngSwitchCase="statusEnum.Assigned" 
                class="badge bg-light-primary round">
                <i  class="fa fa-check-circle  primary font-small-3 mr-1"></i>
                {{getStatusLbl()}}</span>
            <!-- InProgress -->
                <span *ngSwitchCase="statusEnum.InProgress" 
                class="badge bg-light-warning round">
                <i  class="fa fa-spinner spinner  warning font-small-3 mr-1"></i>
                {{getStatusLbl()}}</span>

            <!-- Repaired, Fixed -->
                <span *ngSwitchCase="statusEnum.Fixed" 
                class="badge bg-light-success round">
                <i  class="fa fa-check-circle success font-small-3 mr-1"></i>
                {{getStatusLbl()}}</span>
            <!-- Discarded, Fixed -->
                 <span *ngSwitchCase="statusEnum.Discarded" 
                 class="badge bg-light-danger round">
                 <i  class="fa fa-times-circle font-small-3 mr-1 "></i>
                 {{getStatusLbl()}}</span>
                 
		</ng-container>
    </div>
    <div ngbDropdownMenu  aria-labelledby="ddStatus">
        <button (click)="setStatus(item)" *ngFor="let item of statusItems | enumStringToArray" class="dropdown-item font-small-2">{{ "COMMON.DETECTION_STATUSES." + item | translate}}</button>
      </div>
    </div>
</ng-container>

import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const ROUTES: Routes = [
 
  {
    path: 'maps',
    loadChildren: () => import('../../modules/map.module/map.module').then(m => m.MapModule)
  },
  {
    path: 'list',
    loadChildren: () => import('../../modules/list-view.module/list.module').then(m => m.ListModule) 
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../modules/dashboard.module/dashboard.module').then(m => m.DashboardModule) 
  }
 
 
]; 


import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({providedIn:'root'})
export class AuthorizeGuard implements CanActivate{
    constructor(private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router)
    {

    }
   
    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     const b = this.authService.isTokenExpired();
    //     return !b? true : this.router.navigate(['/pages/login']);
    // }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
          return this.authService.isAuthenticated()
            .pipe(
              tap(loggedIn => {
                if (!loggedIn) {
                  this.router.navigate(['/pages/login']);
                }
                else if(loggedIn && this.authService.changePasswordReuired())
                  this.router.navigate(['/pages/login-change-password']);
              })
            );
      }
}
/**
 * KEYE-API
 * API for KEYE portal
 *
 * OpenAPI spec version: 2022-12-19
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GetUserResponseDevices } from './getUserResponseDevices';


export interface GetUserResponse { 
    users?: Array<GetUserResponseDevices>;
}

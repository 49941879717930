
declare var window: any;

export class DynamicEnvironment {
    constructor() {
    }
    
    public get environment() {
        return window.config.environment;
    }
    public get cognitoConfig() {
      return window.config.cognito;
    }
    public get apiConfig(){
      return window.config.api;
    }
    public get options(){
      return window.config.options;
    }
    public get defaultMapCenter(){
      return window.config.defaultMapCenter;
    }
}
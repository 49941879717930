import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { ToastrService } from 'ngx-toastr';
import { TOKEN_KEY } from '@shared/constants/app.constants';
import { AuthService } from '@shared/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
    constructor(
        private authService:AuthService,
        private loadingService:LoadingService, 
        private router: Router,
        public toastr: ToastrService,
        private translate: TranslateService) { }
   
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const username = environment.apiConfig.username;
        // const password = environment.apiConfig.password;
        const token =  this.authService.token
        //const authdata = window.btoa(username + ':' + password)
        if (token) {
            request = request.clone({
                setHeaders: { 
                    //Authorization: `Basic ${authdata}`
                    Authorization: `${token}`
                }
            });
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              console.log('event--->>>', event);
            }
            return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this.loadingService.hide();
                let errorMsg = '';
                if (error.error instanceof ErrorEvent) {
                    //error client 
                    errorMsg = `Error: ${error.error.message}`;
                    console.log('Error', errorMsg);
                } else {
                    //error server 
                    errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                    this.toastr.error(errorMsg, this.translate.instant("COMMON.ERRORS.ERROR_TITLE"));
                    const s = environment.apiConfig.basePath;
                    const pathArrayApi = environment.apiConfig.basePath.split( '/' );
                    const pathArrayUrl = error.url.split( '/' );
                    const hostApi =pathArrayApi.length > 1? pathArrayApi[2] : environment.apiConfig.basePath;
                    const hostUrl =pathArrayUrl.length > 1? pathArrayUrl[2] : error.url;
                    if(hostUrl.startsWith(hostApi) && (error.status === 400 ||error.status === 401)){
                        this.authService.signOut();
                        this.router.navigate(['/pages/login']);
                    }
                }
                
                return throwError(errorMsg);
            }));

        }  
    }
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  Input
} from "@angular/core";
import { DataStatus } from "@api-client/enum/data.enum";

import { FilterService } from "@shared/services/filter.service";


@Component({
  selector: "app-rating-class",
  templateUrl: "./rating-class.component.html",
  styleUrls: ["./rating-class.component.scss"]
})
export class RatingClassComponent implements OnInit, OnDestroy {
  @Input()large:boolean;
  @Input()width:number = 0;
  
  statusEnum = DataStatus;
  
  constructor(
    public filterService: FilterService
  ) {
   
  }
  
  getWidthClass(val:number):number{
    const _val = val*100; 
    if(_val > 300)
    return 4;
    else if(_val > 70 && _val < 300)
   return 3
    else if(_val > 30 && _val < 70)
    return 2;
    else if(_val > 1 && _val < 30)
    return 1;
    else return 0
    
  }
  ngOnInit() {
  }

  


  ngOnDestroy() {
   
  }


}

import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Inject, Renderer2, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '@shared/services/layout.service';
import { Subscription } from 'rxjs';

import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@shared/auth/auth.service';
import { LoadingService } from '@shared/services/loading.service';
import { ConfigService } from '@shared/services/config.service';
import { DefaultService, GetOrganizationResponse } from '@api-client/index';
import { UtilityService } from '@shared/services/utility.service';
import { LocalStorageService } from '@shared/services/storage.service';


@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  selectedLanguageText = "English";
  selectedLanguageFlag = "./assets/img/flags/us.png";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  defaultLogoUrl = 'assets/img/logo.svg';
  logoUrl: string;
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;

  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new UntypedFormControl();
  public config: any = {};
  public user: any;
  public organization: GetOrganizationResponse;
  private _subscribers: Array<Subscription> = new Array<Subscription>();
  constructor(
    private localStorageService: LocalStorageService,
    public utilityService: UtilityService,
    private dataControllerService: DefaultService,
    private loadingService: LoadingService,
    private authService: AuthService,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    public configService: ConfigService, private cdr: ChangeDetectorRef) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;


    //this.config.layout.navbar.showDownload
   

    this.user = this.getUser();
        this.getOrganization()
  }
 
  private getOrganization() {

    this._subscribers.push(this.dataControllerService.organizationGet().subscribe(result => {
      this.organization = result
      this.logoUrl = this.organization.logo ?? this.defaultLogoUrl;
      this.cdr.detectChanges();
    }
    ));

  }
  private async getUser() {

    const usr = await this.authService.getUser();
    if (usr.challengeName === 'NEW_PASSWORD_REQUIRED') {
      this.loadingService.hide();
      this.router.navigate(['/pages/login-change-password']);
    }
    return usr;

  }
  ngOnInit() {


    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  ngAfterViewInit() {

    this._subscribers.push(this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    }))
  }

  ngOnDestroy() {

    if (this._subscribers) this._subscribers.forEach(s => s.unsubscribe());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }
  public async logout() {
    this.loadingService.show();
    try {
      await this.authService.signOut();
      this.loadingService.hide();
      this.router.navigate(['/pages/login']);
      console.log("signed out!");
    } catch (error) {
      console.log(error);
    }
  }


  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }
    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    }
    else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue("");
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === 'en') {
      this.selectedLanguageText = "English";
      this.selectedLanguageFlag = "./assets/img/flags/us.png";
    }
    else if (language === 'it') {
      this.selectedLanguageText = "Italiano";
      this.selectedLanguageFlag = "./assets/img/flags/it.png";
    }
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
    else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);



  }



  // toggleNotificationSidebar() {
  //   this.layoutService.toggleNotificationSidebar(true);
  // }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }
}

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-x-chart',
  templateUrl: './x-chart.component.html',
  styleUrls: ['./x-chart.component.scss']
})
export class XChartComponent implements OnInit {

  private _chartData: any;
  private _chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'rgba(255,255,255,.1)'
        }
      }],
      yAxes: [{
        gridLines: {
          color: 'rgba(255,255,255,.1)'
        }
      }]
    },
    legend: { position: 'bottom' }
  };
  @Input() singleDataSet: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() noLoading: boolean;
  @Input() stacked: boolean;

  @Input() set chartData(value: any) {
    if (value) {

      this.load(value);
    }

  }
  get chartData(): any {
    return this._chartData;
  }
  @Input() set chartOptions(value: ChartOptions) {
    if (value) {
      let opt: ChartOptions = {};
      Object.assign(opt, value);
      this._chartOptions = opt;
    }
  }
  get chartOptions(): ChartOptions {
    return this._chartOptions;
  };

  @Input() chartType: ChartType = 'bar';
  @Input() chartLegend = true;

  @Input() chartColors: any = [

    {
      backgroundColor: '#31cb76',
      borderColor: '#31cb76',
    },
    {
      backgroundColor: '#4d6fa8',
      borderColor: '#4d6fa8',
    }
  ];
  @Input() chartLabels: Label[];


  chartPlugins = [];
  constructor(private cdRef: ChangeDetectorRef) { 
    

  }

  ngOnInit(): void {
    Chart.defaults.font.size = 16;
  }
  load(v) {

    if (this.chartOptions.scales && this.chartOptions.scales.xAxes)
      this.chartOptions.scales.xAxes[0].stacked = this.stacked ? true : false;
    if (this.chartOptions.scales && this.chartOptions.scales.yAxes)
      this.chartOptions.scales.yAxes[0].stacked = this.stacked ? true : false;

   

    this._chartData = v;
    this.isLoading = false;
    this.cdRef.detectChanges();
  }
}

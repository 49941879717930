import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  SimpleChanges,
  ChangeDetectorRef
} from "@angular/core";
import { DataStatus } from "@api-client/enum/data.enum";
import { ChangeEntityStatusRequest, DefaultService, GetDetectionResponseItems, GetEntityGroupRequest, GetEntityGroupResponseItems, GroupDetectionsRequest, UngroupDetectionRequest } from "@api-client/index";
import { SlideUpDownPanel } from "@shared/animations/custom-animations";
import { AuthService } from "@shared/auth/auth.service";

import { FilterService } from "@shared/services/filter.service";
import { LoadingService } from "@shared/services/loading.service";
import { UtilityService } from "@shared/services/utility.service";
import { NgxMasonryComponent, NgxMasonryOptions } from "ngx-masonry";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
 

@Component({
  selector: "app-images-dialog",
  templateUrl: "./images-dialog.component.html",
  styleUrls: ["./images-dialog.component.scss"],
  animations:[SlideUpDownPanel]
})
export class ImagesDialogComponent implements OnInit, OnDestroy { 
  //@ViewChild(NgxMasonryComponent, { static: false }) masonry: NgxMasonryComponent;
  private _subscribers: Array<Subscription> = new Array<Subscription>();
  private _element:GetDetectionResponseItems;
  defaultImage:string = "./assets/img/oval.svg";
  images:any = [];
  isChanged:boolean;
  masonryOptions: NgxMasonryOptions = {
    gutter: 10,
    // itemSelector: "#item",
    // columnWidth: "#item",
    // horizontalOrder: true,
    // fitWidth: true,
    // percentPosition: true,
    animations: {}
  };
  isOpen:boolean;
  
  items:any[] = [];
  selectedItems:any[] = [];

  set element(value: GetDetectionResponseItems) {
    
    this._element = value;
    if(value)this.getGroupItems();
 }
  get element(): GetDetectionResponseItems {
     return this._element;
 }
  constructor(
    private authService: AuthService,
    public toastr: ToastrService,
    private cdRef:ChangeDetectorRef,
    private dataControllerService: DefaultService,
    private loadingService:LoadingService,
    public utilityService: UtilityService
  ) {
  }
  ngOnInit() {
    const _self =this;
    _self._subscribers.push(_self.utilityService.currentItemSubject$.subscribe((items:GetDetectionResponseItems[]) => {
      this.isChanged = false;
      this.reset();
      if(items.length == 1 && items[0].entity_uuid)
        this.element = items[0];
      else if(items.length > 1){
        this.loadData(items);
      }
    }))
  }
  
  reset(){
    this.selectedItems = [];
    this.element = undefined;
  }
  getImg(val):string {
    return this.utilityService.getAuthImg(val);
  }
 
  loadData(items:any[]) {
    this.items = items;
    this.loadingService.show();
    //this.images = this._generateImagesList();
    this.isOpen = true;
    // if(this.masonry){
    //   this.masonry.reloadItems();
    //   this.masonry.layout();
    // }
    this.cdRef.detectChanges();
    const _self =this;
    setTimeout(function(){ 
       if(_self.loadingService)
       _self.loadingService.hide()
     }, 1500);
  }
  addSelection(item){
    debugger;
    if(this.element || !this.authService.canEdit())return;
    if(this.isSelected(item))
      {
        const index = this.selectedItems.indexOf(item.entity_uuid);
        if(index>-1)this.selectedItems.splice(index, 1);
      }
      else this.selectedItems.push(item.entity_uuid)
  }
  isSelected(item):boolean{
    if(this.selectedItems.length == 0)return false;
    return this.selectedItems.includes(item.entity_uuid);
  }
  unGroup(item){
    const data_uuid = item.data_uuid;
    this.loadingService.show();
    const _self =this;
    const req:UngroupDetectionRequest = {
      data_uuid:data_uuid
    }
    // const filteredItems = this.items.filter(c=> !this.selectedItems.includes(c.entity_uuid));
    // this.loadData(filteredItems);
    // this.selectedItems = [];
    // this.isChanged = true;
    _self._subscribers.push(_self.dataControllerService.detectionUngroupPost(req).subscribe((result:any) => {
      const filteredItems = this.items.filter(c=> c.data_uuid != data_uuid);
      if(result && result.message)this.toastr.success(result.message,'Success');
      this.loadData(filteredItems);
      this.isChanged = true;
      console.log(result);
    }))

  }
  createGroup(){
    this.loadingService.show();
    const _self =this;
    const req:GroupDetectionsRequest = {
      entities_uuids:this.selectedItems
    }
    // const filteredItems = this.items.filter(c=> !this.selectedItems.includes(c.entity_uuid));
    // this.loadData(filteredItems);
    // this.selectedItems = [];
    // this.isChanged = true;
    _self._subscribers.push(_self.dataControllerService.detectionGroupPost(req).subscribe((result:any) => {
      const filteredItems = this.items.filter(c=> !this.selectedItems.includes(c.entity_uuid));
      this.selectedItems = [];
      if(result && result.message)this.toastr.success(result.message,'Success');
      this.loadData(filteredItems);
      this.isChanged = true;
      console.log(result);
      if(filteredItems.length == 0)this.close();
      
    }))

  }
  close(){
    this.isOpen = false;
    if(this.isChanged)this.utilityService.reloadAllData();
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if (this._subscribers)this._subscribers.forEach(s => s.unsubscribe());
  }
  private getGroupItems(){
    this.loadingService.show();
    this._subscribers.push(
   
      this.dataControllerService.detectionGroupGet(this.element.entity_uuid)
      // _self.mockControllerService.getAllData()
        .subscribe(
          (data) => {
            console.log('callData: detectionGroupGet');
            this.loadData(data.items);
             
          }
        ));
  }
  private _generateImagesList(): any[] {
    const images: any[] = [];
    for (let i = 0; i < 20; i++){
      const image = this.generateRandomImage();
      image.alt = `#${i}`;
      images.push(image);
    }
    return images;
  }

  private generateRandomImage(): any {
    const width = 600;
    const height = (Math.random() * (1000 - 400) + 400).toFixed();
    return {image: `https://picsum.photos/${width}/${height}/?random`};
  }

}

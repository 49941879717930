import {
    Directive,
    Attribute,
    Renderer2,
    ElementRef,
    HostListener, 
    Input} from '@angular/core';
  
  @Directive({
    selector: '[uiImageLoader]'
  })
  export class UiImageLoaderDirective {
    public loader: string = './assets/img/load-loading.gif';
    public onErrorSrc: string = './assets/img/no-image.png';
    private _realSrc:string;
    @Input()
    get realSrc(): string {
        return this._realSrc;
    }
    set realSrc(value: string) {
        this._realSrc = value;
        if(value)this.load();
    }
    constructor(
      private renderer: Renderer2,
      private el: ElementRef) {
        //this.renderer.addClass(this.el.nativeElement, 'loading');
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.loader);
        
      }
    load(){
        const _self = this;
        const img = new Image(); 
        //return;
        img.onload = function () {
            _self.renderer.setAttribute(_self.el.nativeElement, 'src', _self.realSrc)
            //setTimeout(function(){_self.renderer.removeClass(_self.el.nativeElement, 'loading');},500)
        };
        img.onerror = function () {
            _self.renderer.setAttribute(_self.el.nativeElement, 'src', _self.onErrorSrc);
        };
        img.src = _self.realSrc;
    }
    // @HostListener('load') onLoad() {
    //   this.renderer.setAttribute(this.el.nativeElement, 'src', this.realSrc);
    // }
    @HostListener('error') onError() {
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.onErrorSrc);
    }
  }
/**
 * KEYE-API
 * API for KEYE portal
 *
 * OpenAPI spec version: 2022-12-19
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface GetDetectionResponseItems { 
    entity_uuid?: string;
    androidId?: string;
    score?: number;
    width?: number;
    category?: string;
    timestamp?: number;
    image?: string;
    distance?: number;
    location?: string;
    track_id?: string;
    session?: string;
    originalBbox?: string;
    relativeBbox?: string;
    detections?: number;
    status?: number;
}


export enum DataStatus { 
    Detected = "DETECTED",
    Assigned = "ASSIGNED",
    InProgress = "INPROGRESS",
    Fixed = "FIXED",
    Discarded = "DISCARDED"
} 
export enum DataSize { 
VeryLarge =4,
Large =3,
Medium =2,
Small = 1,
NotClassified= 0
} 
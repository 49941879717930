import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ECharts, EChartsOption } from 'echarts';




@Component({
  selector: 'app-e-chart',
  templateUrl: './echart.component.html',
  styleUrls: ['./echart.component.scss'],
})
export class EChartComponent implements OnInit {

  public chartConfig: Partial<EChartsOption>
  chartInstance: ECharts;
  colors = {
    red: ["#954739", "#A0574A", "#AC665B", "#B7766C", "#C3857D", "#CE958F", "#DAA4A0", "#E5B4B1", "#F1C3C2", "#FCD3D3"],
    green: ["#495A26", "#4F6229", "#566A2D", "#5C7230", "#627A34", "#698237", "#6F8A3B", "#75923E", "#7C9A42", "#82A245"],
    blue: ["#285294", "#3B619E", "#4E71A7", "#6180B1", "#748FBA", "#889FC4", "#9BAECD", "#AEBDD7", "#C1CDE0", "#D4DCEA"]
  }
  defaultHeatmapColors = this.colors.blue;

  @Input() isLoading: boolean = false;
  @Input() noLoading: boolean;

  @Input() set chartOptions(value: any) {
    if (value) {
      this.load(value);
    }

  }

  constructor(private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.chartConfig = {
      tooltip: {
        position: 'top'
      },
      grid: {
        height: '80%',
        top: '0%',
        bottom: 0
      },
      xAxis: {
        type: 'category',
        splitArea: {
          show: true
        },
        show: true
      },
      yAxis: {
        type: 'category',
        splitArea: {
          show: true
        }
      },
      visualMap: {
        show: false,
        min: 0,
        max: 100,
        calculable: true,
        color: this.defaultHeatmapColors
      },
      series: [
        {
          type: 'heatmap',
          data: [],
          label: {
            show: false
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          tooltip:{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            textStyle:{
              color: 'rgba(255, 255, 255)'
            }
          }
        }
      ]
    };
  }

  onChartInit(e: ECharts) {
    this.chartInstance = e;
    console.log('on chart init:', e);
  }


  load(v) {

    if(!v){
      return;
    }


    var data = [];
    var min = 0;
    var max = 1;
    v.data.forEach((x, i) => {
      x.forEach((y, j) => {
        min = min > y ? y : min;
        max = max < y ? y : max;
        data.push([j, i, (y && Math.round(y)>0) ? Math.round(y): '-']);
      })
    })

    this.chartInstance?.setOption({

      xAxis: {
        data: v.labelX,
      },
      yAxis: {
        data: v.labelY,
      },
      visualMap: {
        min: min,
        max: max,
        color: v.colors ? this.colors[v.colors] : this.defaultHeatmapColors
      },
      series: [
        {
          data: data,
        }
      ]
    })

    this.isLoading = false;
    this.cdRef.detectChanges();
  }

}

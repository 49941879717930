import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'enumStringToArray'
  })
  export class EnumStringToArrayPipe implements PipeTransform {
    transform(data: Object) {
      const keys = Object.keys(data);
      return keys.map((v) => data[v]);
    }
  }
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from "@angular/core";


import { LoadingService } from "@shared/services/loading.service";


@Component({
  selector: "app-spinner",
  templateUrl:"./spinner.component.html",
  styleUrls: ["./spinner.component.scss"]
})
export class SpinnerComponent implements OnInit, OnDestroy {
 
  show:boolean;
  constructor(
    public loader: LoadingService
  ) {
    this.loader.loading$.subscribe(show => this.show = show);
  }

  @Output() directionEvent = new EventEmitter<Object>();

  ngOnInit() {
   
  }

  ngOnDestroy() {
   
  }

}
